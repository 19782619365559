import React, {useEffect} from 'react';
import Header from "../component/header.js";
import utenti from "../session";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import StaticData from "../utils/StaticData";
import sendRequest from '../utils/Request';
import "./Impostazioni.css";
import { Alert } from '@mui/material';

function Impostazioni() {
  const [adminData, setAdminData] = React.useState({});

  const [costoEnergia, setCostoEnergia] = React.useState("");
  const [res, setRes] = React.useState("");

  const [requestSend, setRequestSend] = React.useState(false);
  const [errorRequest, setErrorRequest] = React.useState(false);

  const [urlLogo, setUrlLogo] = React.useState('');

  useEffect(() => {
    //Fai il check dei dati admin
    setAdminData(JSON.parse(sessionStorage.getItem("adminData")));
  },[]);

  useEffect(() => {
    if(adminData.datiProgetto) setUrlLogo(adminData.datiProgetto['urlLogo']);
  },[adminData]);

  useEffect(() => {
  },[res]);

  const onSubmitLogin = () => {
    if(!costoEnergia){
      setErrorRequest(true);
      return;
    }
    //Controlla i valori dei dati
    let params = {
      "costo":costoEnergia,
      "codice_admin":adminData['codice'],
      "api_key":adminData['api_key'],
      'idProgetto':adminData['id_progetto']
    };
    setRequestSend(true);
    sendRequest('POST',params,StaticData.urlChangeCostEnergy,setRes);
  }

  return (
    <div className="App">
    <Header urlLogo={urlLogo} />
      <div className="formInserimento">
        {errorRequest && <Alert severity="warning">Dati inseriti non validi, richiesta non inviata.</Alert>}
        {requestSend && <Alert severity="info">Richiesta presa in carico, effettuare il logout e rientrare per verificare.</Alert>}
        <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Costo dell'energia (€*kwh)</Form.Label>
                <Form.Control type="text" placeholder="Varia il costo dell'energia" onChange={e => {setCostoEnergia(e.target.value)}}/>
              </Form.Group>
              <Button variant="primary" onClick={onSubmitLogin} style={{ backgroundColor: '#222221', borderColor: '#222221' }}>
                Cambia il costo dell'energia
              </Button>
            </Form>
      </div>
    </div>
  );
}

export default Impostazioni;
