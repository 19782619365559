import React, {useEffect} from 'react';
import Header from "../component/header.js";
import StaticData from "../utils/StaticData";
import sendRequest from '../utils/Request';
import UserController from '../component/userController.js';
import './ManagerUser.css';
import { Typography } from '@mui/material';


function ManageUser() {
  const [adminData, setAdminData] = React.useState({});
  const [usersData, setUsersData] = React.useState([]);

  const [urlLogo, setUrlLogo] = React.useState('');

  useEffect(() => {
    //Fai il check dei dati admin
    setAdminData(JSON.parse(sessionStorage.getItem("adminData")));
  },[]);

  useEffect(() => {
    if(adminData.datiProgetto) setUrlLogo(adminData.datiProgetto['urlLogo']);
    let params = {
        'codice':adminData['codice'],
        'apiKey':adminData['api_key'],
        'codiceProgetto':adminData['id_progetto']
    }
    if(params.apiKey && params.codice) sendRequest('GET',params,StaticData.urlGetUsersFromAdmin,setUsersData);
  },[adminData]);

  useEffect(() => {
  },[usersData]);

  return (
    <div className="App">
    <Header urlLogo={urlLogo} />
      <div className="bodyManageUser">
        <div>
        <Typography variant="h3" gutterBottom sx={{ color: 'black' }}>
            Gestione utenti
        </Typography>
        </div>
        {usersData.userDatas ? <UserController usersData={usersData.userDatas} adminData={adminData}></UserController> : ''}
      </div>
    </div>
  );
}

export default ManageUser;