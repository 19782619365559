import React from 'react';
import './header.css';
import {Routes, Route, useNavigate} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

let goToPage = (path, navigate) => {
  navigate('/' + path);
}

const pages = [{ titolo:'Homepage', path: 'home'}, { titolo:'Aggiungi utente', path: 'add-user'}, { titolo:'Gestisci utenti', path: 'manage-user'}];
const settings = [{titolo: 'Impostazioni', path: 'impostazioni'}, {titolo: 'Logout', path: 'logout'}];

function Header(props) {
    const navigate = useNavigate();

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
  
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };

    const doLogout = () => {
      sessionStorage.clear();
      navigate('/');
    }

    const onClickMenuElement = (path, toClose) => {
      if(path === 'logout'){
        doLogout();
        return;
      } 
      if(toClose){
        handleCloseNavMenu();
        handleCloseUserMenu();
      }
      goToPage(path, navigate);
    }
  
    return (
      <AppBar position="static" sx={{ backgroundColor: '#222221'}}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <img src={props.urlLogo} className="logoHeader" />
  
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.titolo} onClick={() => {onClickMenuElement(page.path, true)}}>
                    <Typography textAlign="center">{page.titolo}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={page.titolo}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                  onClick={() => {onClickMenuElement(page.path, true)}}
                >
                  {page.titolo}
                </Button>
              ))}
            </Box>
  
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <AccountCircleIcon fontSize='large' sx={{color: 'white'}}/>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting.titolo} onClick={() => {onClickMenuElement(setting.path, true)}}>
                    <Typography textAlign="center">{setting.titolo}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );

}

export default Header;
