import React, {useEffect} from 'react';
import Switch from "react-switch";
import StaticData from "../../utils/StaticData";
import sendRequest from '../../utils/Request';

function UserControllerLine(props) {

    const [user, setUser] = React.useState(props.userData)
    const [booleanSwitch, setBooleanSwitch] = React.useState(props.userData.status);
    const key = props.key;
    const [adminData, setAdminData] = React.useState(props.adminData);
    const [res, setRes] = React.useState(""); 

    useEffect(() => {
        if(typeof booleanSwitch === "boolean"){
            const params = {
                'codice':adminData['codice'],
                'apiKey':adminData['api_key'],
                'codiceUtente':user['id_tessera'],
                'newStatus': (booleanSwitch ? 1 : 0),
                'idProgetto':adminData['id_progetto']
            }
            if(params.apiKey && params.codice && params.codiceUtente) sendRequest('POST',params,StaticData.urlChangeUserStatus,setRes,true);
        }
    },[booleanSwitch])

    return(
        <tr key={key}>
        <td>{user.nome}</td>
        <td>{user.cognome}</td>
        <td>{user.id_tessera}</td>
        <td><Switch checked={booleanSwitch === '1' || booleanSwitch === true || booleanSwitch === 1} onChange={setBooleanSwitch} /></td>  
      </tr> 
    );

}

export default UserControllerLine;