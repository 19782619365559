import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import "./Homepage.css";
import Header from "../component/header.js";
import sendRequest from "../utils/Request";
import StaticData from "../utils/StaticData";
import { Typography } from "@mui/material";


function getNumberOfPages(rowCount, rowsPerPage) {
  return Math.ceil(rowCount / rowsPerPage);
}

function toPages(pages) {
  const results = [];

  for (let i = 1; i < pages; i++) {
    results.push(i);
  }

  return results;
}

const columns = [
  {
    name: "Nominativo",
    selector: (row) => row.nomeUtente,
    sortable: true
  },
  {
    name: "KWH Consumati",
    selector: (row) => row.correnteConsumata,
    sortable: true
  },
  {
    name: "Costo energia",
    selector: (row) => row.costoEnergia,
    sortable: true
  },
  {
    name: "Data",
    selector: (row) => row.timestamp,
    sortable: true,
    right: true
  }
];

// RDT exposes the following internal pagination properties
const BootyPagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage, // available but not used here
  currentPage
}) => {
  const handleBackButtonClick = () => {
    onChangePage(currentPage - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(currentPage + 1);
  };

  const handlePageNumber = (e) => {
    onChangePage(Number(e.target.value));
  };

  const pages = getNumberOfPages(rowCount, rowsPerPage);
  const pageItems = toPages(pages);
  const nextDisabled = currentPage === pageItems.length;
  const previosDisabled = currentPage === 1;

  return (
    <nav>
      <ul className="pagination">
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleBackButtonClick}
            disabled={previosDisabled}
            aria-disabled={previosDisabled}
            aria-label="previous page"
          >
            Precedente
          </button>
        </li>
        {pageItems.map((page) => {
          const className =
            page === currentPage ? "page-item active" : "page-item";

          return (
            <li key={page} className={className}>
              <button
                className="page-link"
                onClick={handlePageNumber}
                value={page}
              >
                {page}
              </button>
            </li>
          );
        })}
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleNextButtonClick}
            disabled={nextDisabled}
            aria-disabled={nextDisabled}
            aria-label="next page"
          >
            Successiva
          </button>
        </li>
      </ul>
    </nav>
  );
};

const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="form-check">
    <input
      htmlFor="booty-check"
      type="checkbox"
      className="form-check-input"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
    <label className="form-check-label" id="booty-check" />
  </div>
));

export default function Home() {

  const [userData, setUserData] = React.useState({
    'response': false,
    'userData':[]
  });

  const [adminData, setAdminData] = React.useState({});

  const [listSessions, setListSession] = React.useState([]);

  const [urlLogo, setUrlLogo] = React.useState('');

  const getCostoEnergia = (kwh) => {
    return kwh*(adminData.configurazione['kwh']);
  }


  //Start
  useEffect(() => {
    //Fai il check dei dati admin
    setAdminData(JSON.parse(sessionStorage.getItem("adminData")));
  },[]);

  useEffect(() => {
    let params = {
      'codice':adminData['codice'],
      'apiKey':adminData['api_key'],
      'idProgetto':adminData['id_progetto']
    }
    if(adminData.datiProgetto) setUrlLogo(adminData.datiProgetto['urlLogo']);
    sendRequest('GET',params,StaticData.urlGetUserData,setUserData);
  },[adminData]);

  useEffect(() => {
    if(userData.response === true){
      let tmp = [];
      for(let i = 0; i < userData.userDatas.length; i++){
        for(let j = 0; j < userData.userDatas[i]['lista_sessioni'].length; j++){
          let timeInt = parseInt(userData.userDatas[i]['lista_sessioni'][j]['timestamp']*1000);
          let val = {
            nomeUtente: userData.userDatas[i]['nome'] + " " + userData.userDatas[i]['cognome'],
            timestamp:  new Date(timeInt).toLocaleDateString("it-IT"),
            costoEnergia: getCostoEnergia(userData.userDatas[i]['lista_sessioni'][j]['kwh']) + "€",
            correnteConsumata: userData.userDatas[i]['lista_sessioni'][j]['kwh'],
          }
          tmp.push(val);
        }
      }
      setListSession(tmp);
    }
  },[userData]);


  return (
    <div className="App">
      <Header urlLogo={urlLogo} />
      <div style={{ marginTop: '16px' }}>
        <Typography variant="h3" gutterBottom sx={{ color: 'black' }}>
          Sessioni di ricarica
        </Typography>
        <DataTable
          columns={columns}
          data={listSessions}
          defaultSortFieldID={1}
          pagination
          paginationComponent={BootyPagination}

        />
      </div>
    </div>
  );
}
