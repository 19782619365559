import React, {useEffect} from 'react';
import './userController.css';
import UserControllerLine from './microComponent/UserControllerLine';

function UserController(props) {

  const [usersList, setUsersList] = React.useState(props.usersData);
  const [adminData, setAdminData] = React.useState(props.adminData);

  return (
    <div className="container">
      <table>
        <tr>
          <th>Nome</th>
          <th>Cognome</th>
          <th>ID tessera</th>
          <th>Stato</th>
        </tr>
        {usersList.map((user, key) => {
          return (
            <UserControllerLine userData={user} key={key} adminData={adminData}/>
          )
        })}
      </table>
    </div>
  );

}

export default UserController;


// <td>{user.status}</td>
