import logo from './logo.svg';
import React from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";
import Home from './pages/Homepage.js';
import AddUser from './pages/AddUser.js';
import Impostazioni from './pages/Impostazioni.js';
import Login from './pages/Login.js';
import ManageUser from './pages/ManageUser';

function App() {
  
  return (
    <HashRouter>
     <Routes>
       <Route path="/" element={<Login />} />
       <Route exact path="/login" element={<Login />} />
       <Route exact path="/home" element={<Home />} />
       <Route exact path="/add-user" element={<AddUser />} />
       <Route exact path="/impostazioni" element={<Impostazioni />} />
       <Route exact path="/manage-user" element={<ManageUser />} />
     </Routes>
   </HashRouter>
  );
}

export default App;
