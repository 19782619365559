import React from 'react';
import './Login.css';
import logo from "../assets/en.png";
import { useNavigate} from 'react-router-dom';
import StaticData from "../utils/StaticData";
import sendRequest from '../utils/Request';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Alert } from '@mui/material';

function Login() {

  const navigate = useNavigate();

  const [codice, setCodice] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [error, setError] = React.useState(false);
  const [missingData, setMissingData] = React.useState(false);

  const theme = createTheme();

  const verifyLogin = (serverResponse) => {
    if(serverResponse.response){
      sessionStorage.setItem("adminData", JSON.stringify(serverResponse.userData));
      sessionStorage.setItem("isLogged", true);
      navigate('/home');
    }else{
      setMissingData(false);
      setError(true);
    }
  }

  const onSubmitLogin = () => {
    if(!codice || !password){
      setError(false);
      setMissingData(true);
      return;
    }
    let params = {
      "codice":codice,
      "password":password
    };
    sendRequest('POST',params,StaticData.urlLogin,verifyLogin);
  }


  const Copyright = (props) => {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="#">
          Energy Controller
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  const LoginErrato = (
    <Alert severity="error">Login errato, riprovare!</Alert>
  );

  const DatiMancanti = (
    <Alert severity="warning">Dati mancanti!</Alert>
  );

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logo} className="logo" />
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="codice"
              label="Codice utente"
              name="codice"
              autoComplete="codice"
              autoFocus
              onChange={e => {setCodice(e.target.value)}}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={e => {setPassword(e.target.value)}}
            />
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={onSubmitLogin}
            >
              Login
            </Button>
          </Box>
        </Box>
        { error && LoginErrato}
        { missingData && DatiMancanti}
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );

}

export default Login;
