import axios from "axios";

const sendRequest = (type, params, url, callback, log=false) => {
    if(type == 'GET'){
        axios.get(url,{
            params:params
        })
        .then(res => {
            if(log) console.log(res);
            callback(res.data)
        })
    }
    if(type == 'POST'){
        axios.post(url, params)
        .then(res => {
          if(log) console.log(res);
          callback(res.data);
        })
    }
    return { 'error': 'Request method not valid' }
}

export default sendRequest;

