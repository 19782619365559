
class StaticData {

    static baseUrl = "https://xploroserver.cloud/energycontrollersystem/server/api/v1/";

    static urlLogin = this.baseUrl + "login_admin.php";
    static urlGetUserData = this.baseUrl + "admin_get_datas.php";
    static urlInsertUser = this.baseUrl + "insert_user.php";
    static urlChangeCostEnergy = this.baseUrl + "change_cost_energy.php";
    static urlGetUsersFromAdmin = this.baseUrl + "get_users_from_admin.php";
    static urlChangeUserStatus = this.baseUrl + "change_status_user.php";
}

export default StaticData;