import React, {useEffect} from 'react';
import "./AddUser.css";
import Header from "../component/header.js";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import StaticData from "../utils/StaticData";
import sendRequest from '../utils/Request';
import { Alert, Typography } from '@mui/material';

function AddUser() {

  const [adminData, setAdminData] = React.useState({});

  const [nome, setNome] = React.useState("");
  const [cognome, setCognome] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [tessera, setTessera] = React.useState("");
  const [psw, setPsw] = React.useState("");

  const [res, setRes] = React.useState("");

  const [urlLogo, setUrlLogo] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [uploadDone, setUploadDone] = React.useState(false);

  const [errorInsertData, setErrorInsertData] = React.useState(false);

  //Start
  useEffect(() => {
    //Fai il check dei dati admin
    setAdminData(JSON.parse(sessionStorage.getItem("adminData")));
  },[]);

  useEffect(() => {
    if(adminData.datiProgetto) setUrlLogo(adminData.datiProgetto['urlLogo']);
  },[adminData])

  useEffect(() => {
    if(res !== ''){
      setUploadDone(true);
      setLoading(false);
    }
  },[res])

  const controlMissingData = () => {
    if(nome === "" || cognome === "" || email === "" || tessera === "" || psw === ""){
      setErrorInsertData(true);
      return true;
    }
    return false;
  }

  const onSubmitLogin = () => {
    //Controlla i valori dei dati
    if(controlMissingData()) return;
    let params = {
      "nome":nome,
      "cognome":cognome,
      "email":email,
      "tessera":tessera,
      "psw":psw,
      "codice_admin":adminData['codice'],
      "api_key":adminData['api_key'],
      'idProgetto':adminData['id_progetto']
    };
    setLoading(true);
    sendRequest('POST',params,StaticData.urlInsertUser,setRes);
  }


  return (
<div className="App">
  <Header urlLogo={urlLogo} />
  <div className="formInserimento">
    { loading && <Alert severity="info">Richiesta di inserimento dell'utente inviata.. verificare nella lista.</Alert> }
    { uploadDone && <Alert severity="success">Richieste terminata.</Alert> }
    { errorInsertData && <Alert severity="warning">Dati mancanti!</Alert> }
    <Typography variant="h3" gutterBottom sx={{ color: 'black' }}>
        Inserisci un nuovo utente
    </Typography>
    <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Nome</Form.Label>
            <Form.Control type="text" placeholder="Inserisci nome" onChange={e => {setNome(e.target.value)}}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Cognome</Form.Label>
            <Form.Control type="text" placeholder="Inserisci cognome" onChange={e => {setCognome(e.target.value)}}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Indirizzo Email</Form.Label>
            <Form.Control type="email" placeholder="Inserisci email" onChange={e => {setEmail(e.target.value)}}/>
            <Form.Text className="text-muted">
              Questo indirizzo email verrà utilizzato per le comunicazioni di servizio
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>ID Tessera</Form.Label>
            <Form.Control type="email" placeholder="Inserisci id tessera" onChange={e => {setTessera(e.target.value)}}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" onChange={e => {setPsw(e.target.value)}}/>
          </Form.Group>

          <Button variant="primary" onClick={onSubmitLogin} style={{ backgroundColor: '#222221', borderColor: '#222221' }}>
            Inserisci nuovo utente
          </Button>
        </Form>
  </div>

</div>
  );
}

export default AddUser;
